// styles
import "./App.css";
// react
import { useEffect, useState } from "react";
// firebase
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD_JS4cGcJFsUCzI-Mm6Z0TQu452F_MPIs",
  authDomain: "brokenpixels-2f2a0.firebaseapp.com",
  projectId: "brokenpixels-2f2a0",
  storageBucket: "brokenpixels-2f2a0.appspot.com",
  messagingSenderId: "423316428116",
  appId: "1:423316428116:web:a8d5e7c2738187bb1f64c1",
  measurementId: "G-LNCLX1P8TR",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
  const [isHidden, setIsHidden] = useState(false);

  setTimeout(() => setIsHidden(true), 2000);

  useEffect(() => {
    logEvent(analytics, "notification_received");
  }, []);

  return (
    <div className="App">
      <div className={isHidden ? "hidden" : "hero-img"}>
        <img
          alt="cats yo"
          src="https://media3.giphy.com/media/VIiVYTrcbmNiTeD037/giphy.gif?cid=ecf05e47rzpqg8dnna2x4uxrf6ogcy1jrzi2q90rqexigg8j&rid=giphy.gif&ct=s"
        />
      </div>
      <div className={isHidden ? "content" : "hidden"}>
        <div className="logo">100</div>
        <div className="slogan">BUILDING THE APPS OF TOMORROW</div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <a
            href="mailto:customerservice@100brokenpixels.com?cc=ceo@100brokenpixels.com&subject=Hi we think you're rad!"
            style={{
              margin: "2%",
              zIndex: 1000,
              textDecorationLine: "none",
              color: "inherit",
              fontWeight: "bolder"
            }}
          >
            📧 CONTACT US
          </a>

          <div>
            <small style={{ fontSize: "0.5rem" }}>
              © 100 Broken Pixels, LLC. 2023
            </small>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
